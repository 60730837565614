import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getFcodeByPath } from '../GlobalFunction/GlobalFunction';

function KeyEventHandler() {
  const navigator = useNavigate();

  useEffect(() => {
    const keyMap = {
      S: `/addSaleInvoices/${getFcodeByPath("addSaleInvoices")}`,
      P: `/addPurchaseBills/${getFcodeByPath("addPurchaseBills")}`,
      R: `/addCreditNote/${getFcodeByPath("addCreditNote")}`,
      I: `/paymentin/${getFcodeByPath("paymentin")}`,
      O: `/paymentout/${getFcodeByPath("paymentout")}`,
      E: `/expensive/addExpensive/${getFcodeByPath("expensive/addExpensive")}`,
      L: `/addDebitNote/${getFcodeByPath("addDebitNote")}`,
      F: `/saleaddorder/${getFcodeByPath("saleaddorder")}`,
      A: `/additems/${getFcodeByPath("additems")}`,
    };

    const handleKeyDown = (event) => {
      if (event.altKey) {
        const route = keyMap[event.key.toUpperCase()];
        if (route) {
          event.preventDefault(); 
          navigator(route);
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [navigator]);

  return null;
}

export default KeyEventHandler;
