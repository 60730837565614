import { createContext } from 'react';
import io from 'socket.io-client';
const apiUrl = process.env.REACT_APP_API_BASE_URL;

const socket = io(apiUrl, {
  transports: ['websocket', 'polling'],
  reconnection: true,
  reconnectionAttempts: 5,
  reconnectionDelay: 2000,
});

export const SocketContext = createContext(socket);
export { socket };


// import { createContext } from 'react';
// import io from 'socket.io-client';

// // Dynamically select the protocol (ws:// or wss://) based on the environment
// const protocol = window.location.protocol === 'https:' ? 'wss://' : 'ws://';

// // Ensure the API base URL is correct
// const apiUrl = `${protocol}${process.env.REACT_APP_API_BASE_URL}`;

// // Initialize Socket.IO with WebSocket as the preferred transport
// const socket = io(apiUrl, {
//   transports: ['websocket', 'polling'],
//   reconnection: true,
//   reconnectionAttempts: 5, // Retry up to 5 times
//   reconnectionDelay: 2000,  // Delay 2 seconds between attempts
// });

// // Create the SocketContext
// export const SocketContext = createContext(socket);
// export { socket };
