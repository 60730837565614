import React, { Suspense, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Header from "../components/Header/Header";
import Sidebar from "../components/Sidebar/Sidebar";

function Layout() {

    const [siderOpen, setSidebarOpen] = useState(() => {
        const storedValue = localStorage.getItem("siderOpen");
        return storedValue ? JSON.parse(storedValue) : false;
    });

    useEffect(() => {
        localStorage.setItem("siderOpen", JSON.stringify(siderOpen));
    }, [siderOpen]);

    return (
        <React.Fragment>
            <Header siderOpen={siderOpen} setSidebarOpen={(value) => setSidebarOpen(value)} />
            <Sidebar siderOpen={siderOpen} setSidebarOpen={(value) => setSidebarOpen(value)} />
            <div className={`board ${siderOpen ? "DashboardOpen" : "DashboardColse"}`}>
                <Suspense><Outlet /></Suspense>
            </div>
        </React.Fragment>
    );
}

export default Layout;